<script setup lang="ts">
import {
  type INodeObject,
  joinPath,
  parseSizeToGrid,
  useObjectVModel,
  useRepeater,
  FieldInputTypes,
  NodeKind,
} from '@manager'
import type { IManagerRepeaterEmits, IManagerRepeaterProps } from './types'

const props = withDefaults(defineProps<IManagerRepeaterProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerRepeaterEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const objectPath = computed(() => joinPath(props.parentPath, objectNode.name))
const joinDataPath = (index: number) =>
  joinPath(objectPath.value, `data[${index}]`)
const [parentValue, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
  true,
)

const {
  items,
  filteredItems,
  canAdd,
  canRemove,
  add,
  update,
  remove,
  getById,
} = useRepeater(dataModelValue, { min: props.node.min, max: props.node.max })
</script>

<template>
  <div
    :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']"
    :data-path="objectPath"
  >
    <div v-if="node.label" class="text-base text-white">{{ node.label }}</div>
    <template v-for="(item, index) in filteredItems" :key="item.__id">
      <slot v-bind="{ items, filteredItems, item, add, remove }">
        <ManagerSharedGroupGrid>
          <template
            v-for="childNode in objectNode.nodes"
            :key="resolveNodeId(childNode)"
          >
            <ManagerNode
              v-model:parent-value="filteredItems[index]"
              :class="{
                'mt-6 self-center':
                  childNode.kind === NodeKind.FIELD &&
                  childNode.type === FieldInputTypes.CHECKBOX,
              }"
              :node="childNode"
              :parent-path="joinDataPath(index)"
              :parent-node="objectNode"
            />
          </template>
          <div class="flex items-center">
            <Button
              v-if="canRemove"
              color="transparentSecondary"
              icon="close"
              class="mt-6 px-2 py-2"
              @click.prevent="remove(item.__id)"
            />
          </div>
        </ManagerSharedGroupGrid>
      </slot>
    </template>

    <div v-if="canAdd">
      <Button size="sm" color="secondary" icon="plus" @click.prevent="add()">
        {{ node.addButton ?? 'Add' }}
      </Button>
    </div>
  </div>
</template>
